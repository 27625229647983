import FingerprintJS from '@fingerprintjs/fingerprintjs'
import dayjs from 'dayjs'

const getClient = (width?: number) => {
  return (width ? width : document.body.clientWidth) < 640 ? 'mobile' : 'pc'
}

const addParamsToUrl = (url: string, params: { [key: string]: string }) => {
  try {
    const urlObj = new URL(url)
    const searchParams = urlObj.searchParams

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key]
        searchParams.append(key, value)
      }
    }

    return urlObj.toString()
  } catch (error) {
    return url
  }
}

const getFileNameFromUrl = (url?: string): string => {
  if (!url) {
    return ''
  }

  const arr = url.split('/') || []
  return arr[arr.length - 1].split('.')[0]
}

const east8TimestampToLocal = (east8Timestamp: number): number => {
  const east8Date = new Date(east8Timestamp)
  const timeDifferenceInMilliseconds = (-8 * 60 - new Date().getTimezoneOffset()) * 60 * 1000
  const localTimestamp = east8Date.getTime() - timeDifferenceInMilliseconds
  return localTimestamp
}

const east8TimestampToDateTime = (east8Timestamp: number): string => {
  const date = new Date(east8TimestampToLocal(east8Timestamp))
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const timeRemaining = (utcTimestamp: number, expireSeconds: number): number => {
  const timestamp = east8TimestampToLocal(utcTimestamp)
  const currentTimestamp = new Date().getTime()
  const expirationTime = timestamp + expireSeconds * 1000
  const remainingTime = expirationTime - currentTimestamp

  return remainingTime <= 0 ? 0 : Math.floor(remainingTime / 1000)
}

const coverUrlToFit = (url: string, size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'): string => {
  const newUrl = url.replace(/_\d+x\d+\.(jpg|png)/, '.$1')

  if (['xs', 'sm'].indexOf(size) >= 0) {
    return newUrl.replace(/\.(jpg|png)$/, '_640x320.$1')
  }
  return newUrl
}

const getBaseHost = (env: string): string => {
  const protocol = window.location.protocol
  console.log('env', env)
  if (env === 'development') {
    // return `https://www-origin.outtabounds.au/api`
    // return `//192.168.50.2:8081`
    return `//192.168.50.33:8081`
    return `//tgadev.just4fun.asia`
  }
  if (env === 'test') {
    return `https://data.just4fun.sg`
  }
  if (env === 'production') {
    return `https://www-origin.outtabounds.au/api`
  }
  return `${protocol}//data.just4fun.sg`
}

export const roundUpToNearest15Minutes = (minutes: number): number => {
  return Math.ceil(minutes / 15) * 15
}

export const getCurrentTime = (): string => {
  const now = dayjs()
  const hours = now.hour()
  const minutes = now.minute()
  const formattedMinutes = roundUpToNearest15Minutes(minutes)
  return `${String(hours).padStart(2, '0')}:${String(formattedMinutes).padStart(2, '0')}`
}

export const createFpId = async (): Promise<string> => {
  const fp = await FingerprintJS.load()
  return (await fp.get()).visitorId
}
export const convertMinutesToHours = (minutes: string): string => {
  const minutesNumber = parseFloat(minutes)
  if (isNaN(minutesNumber)) return '0'
  return (minutesNumber / 60).toString()
}
export {
  addParamsToUrl,
  coverUrlToFit,
  east8TimestampToDateTime,
  getBaseHost,
  getClient,
  getFileNameFromUrl,
  timeRemaining
}
